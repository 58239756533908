import React, { useState } from 'react'
import apiClient from '../../../js/apiclient'
import * as styles from "./usersettings.module.css"
import util from '../../../js/util';
import toast from 'react-hot-toast';
import { IoWarning } from "react-icons/io5";

const ChError = ({children}) => {
    const hasError = children !== undefined
    return (
        <div style={{
                visibility: hasError ? "visible" : "hidden"
            }}>
            <div className={ styles.authError }>
                <div className={styles.warning}><span><IoWarning /></span></div>
                { children }
            </div>
        </div>
    )
}

const defaultState = {
    passwords: {
        oldPassword: "",
        newPassword: "",
        repeatPassword: "",
    },
    field: "",
    err: {
        errMsg: undefined,
        errCode: 1,
    }
}

const ChangePassword = () => {
    const [chState, setChState] = useState(defaultState)
    const errMsg = chState.err.errMsg
    const errCode = chState.err.errCode
    const hasChError = errMsg !== undefined
    const allEmpty = chState.passwords.newPassword === "" || chState.passwords.repeatPassword === ""

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        let errMsg = undefined
        let errCode = chState.err.errCode
        const updState = Object.assign({}, chState)
        updState.passwords[name] = value
        updState.field = name

        if (name === "newPassword") {
            errMsg = util.validateNewPassword(updState.passwords.newPassword)
            errCode = errMsg === undefined ? 0 : 1
            updState.passwords["retryPassword"] = ""
        } else if (name === "repeatPassword") {
            errMsg = util.validateRepeatPassword(updState.passwords.repeatPassword, updState.passwords.newPassword)
            errCode = errMsg === undefined ? 0 : 2
        }

        const err = Object.assign({}, {errMsg: errMsg, errCode: errCode})
        updState.err = err

        setChState(updState)
    }

    const handleNewFocus = (event) => {
        const name = event.target.name
        const updState = Object.assign({}, chState)
        updState.passwords[name] = ""

        if (name == "newPassword") {
            updState.passwords.repeatPassword = ""
        }

        setChState(updState)
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        apiClient.changePassword(chState.passwords.oldPassword, chState.passwords.newPassword)
        .then(response => {
            if (response.status === 500) {
                toast.error("Could not change user password!", {position: 'top-right', duration: 5000})
            } else {
                toast.success("Password was successfully changed!", {position: 'top-right'})
            }

            setChState(defaultState)
        })
    }

    const submitStyle = hasChError ? styles.formDisable + "" : allEmpty ? styles.formDisable + "" : " "

    return (
        <div>
            <ChError>{ errMsg }</ChError>
            <div id="changePassword" className={ styles.quicksignup }>
                <form onSubmit={handleSubmit}>
                    <label>
                    Old password:
                    <input
                        name="oldPassword"
                        type="password"
                        placeholder="old password" 
                        value={chState.passwords.oldPassword}
                        onChange={handleInputChange} onFocus={handleNewFocus} />
                    </label>
                    <br />
                    <label>
                    New password:
                    <input
                        name="newPassword"
                        type="password"
                        placeholder="new password"
                        value={chState.passwords.newPassword}
                        onChange={handleInputChange} onFocus={handleNewFocus} />
                    </label>
                    <br />
                    <label>
                    Repeat new password:
                    <input disabled={errCode === 1}
                        name="repeatPassword"
                        type="password"
                        placeholder="repeat password"
                        value={chState.passwords.repeatPassword}
                        onChange={handleInputChange} onFocus={handleNewFocus} />
                    </label>
                    <input className={submitStyle} type="submit" value="Submit"/>
                </form>
            </div>
        </div>
    )
}

export default ChangePassword