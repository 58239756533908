import React from 'react';
import ChangePassword from '../../forms/usersettings/changePassword';
import * as styles from "./settingsview.module.css";
import util from '../../../js/util';
import SettingSlice from './settingslice';
import SettingsLayout from '../../layouts/settingslayout';

const SettingsView = () => {
    const changePasswordHelp = <div className={styles.helpInfo}>
        <p>We encourage our users to use strong passwords. Here's the requirements for the new password...</p>
        <ul>
            <li>Minimum password length is 8characters</li>
            <li>At least one lower case caracter</li>
            <li>At least one upper case caracter</li>
            <li>At least one digit</li>
            <li>At least one special caracter: </li>
        </ul>
    </div>

    return (
        <SettingsLayout title="Account Settings" backAction={() => util.navigateToPage("/dashboard")} backTooltip="Back to Dashboard">
            <SettingSlice title="Change Password" help={changePasswordHelp}>
                <ChangePassword />
            </SettingSlice>
        </SettingsLayout>
    )
}

export default SettingsView