import React, { useEffect, useState } from 'react';
import SettingsView from '../components/views/settingsview/settingsview';
import util from '../js/util';
import Loader from "../components/common/loader/loader";
import Seo from '../components/seo';

const Settings = () => {
  const [authenticated, setAuthenticated] = useState(false)
  
  useEffect(() => {
      util.checkLogin().then(res => {
          if(res) {
              setAuthenticated(true)
          }
      })
      const intervalId = setInterval(() => {
          util.checkLogin()
      }, 5000)

      return () => clearInterval(intervalId)
  }, [])

  let content = <Loader />
  if (authenticated) {
      content = <SettingsView />
  }

  return (
    <div>
        { content }
    </div>
  )
}

export default Settings

export const Head = () => <Seo title="Account Settings" />